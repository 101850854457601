/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "core-js/stable"
import "regenerator-runtime/runtime"

console.log('Hello World from Webpacker')

import $ from "jquery"
import "jquery-ujs/src/rails.js"

import 'bootstrap/dist/js/bootstrap.bundle.js'
import 'bootstrap/scss/bootstrap.scss'

import Vue from 'vue'

import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter)

import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)
import 'bootstrap-vue/dist/bootstrap-vue.css'

import "fork-awesome/scss/fork-awesome.scss"

import L from 'leaflet'
import 'leaflet-gpx'

delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

import 'leaflet/dist/leaflet.css'

import Chartkick from "vue-chartkick"
import Chart from "chart.js"
 
Vue.use(Chartkick.use(Chart))

import Turbolinks from 'turbolinks'
Turbolinks.start()

import axios from 'axios'
Vue.prototype.$http = axios

import moment from 'moment'
Vue.prototype.moment = moment

Vue.component('AutocompleteInput', () => import('src/AutocompleteInput.vue'))
Vue.component('DatepickerInput', () => import('src/DatepickerInput.vue'))
Vue.component('StyleInput', () => import('src/StyleInput.vue'))
Vue.component('GeoRefMap', () => import('src/GeoRefMap.vue'))
Vue.component('GeoRefMapMarker', () => import('src/GeoRefMapMarker.vue'))
Vue.component('GeolocationInput', () => import('src/GeolocationInput.vue'))
Vue.component('SpoilerOverlay', () => import('src/SpoilerOverlay.vue'))
Vue.component('PhotoGallery', () => import('src/PhotoGallery.vue'))
Vue.component('SortableList', () => import('src/SortableList.vue'))
Vue.component('PartialDateInput', () => import('src/PartialDateInput.vue'))
Vue.component('StrongPasswordInput', () => import('src/StrongPasswordInput.vue'))
Vue.component('SelectableList', () => import('src/SelectableList.vue'))
Vue.component('TagsInput', () => import('src/TagsInput.vue'))
Vue.component('ClipboardButton', () => import('src/ClipboardButton.vue'))
Vue.component('AdvancedSearch', () => import('src/AdvancedSearch.vue'))

document.addEventListener('turbolinks:load', () => {
  axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  var vueapp = new Vue({
    el: "#vue-app",
  });
  $('[data-toggle="tooltip"]').tooltip()
});
